import React, { useState, useEffect, useRef } from 'react'; // Import useRef
import ReactDOM from 'react-dom';
import styled from 'styled-components';

import JSZip from "jszip";
import { saveAs } from 'file-saver';
import { LazyLoadImage } from "react-lazy-load-image-component";
import ThumbnailView  from "./ThumbnailView.tsx";

const Container = styled.div`
    display: grid;
    grid-template-columns:  1fr;
    gap: 30px;
    row-gap:10px;
    

`
const ParentContainer = styled.div`
    padding-left: 100px;
    padding-right: 100px;
    padding-bottom: 50px;
    height:80vh;
    background-color: #f5f5f4;
    overflow-y: auto; /* Enable vertical scroll */
    overflow-x: hidden; /* Enable vertical scroll */

`
const ImageContainer = styled.div`
    text-align:center;
    border-radius: 3px;
    margin: 2px;
    padding:10px;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: all .2s ease-in-out;
    &:hover {
        transform: scale(1.05);
        cursor: pointer;
     }
`

const ImageContainerSelected = styled.div`
    width:100%;
    text-align:center;
    border-radius: 3px;
    margin: 1px;
    padding:10px;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: all .2s ease-in-out;
    border: 1px solid black;
    &:hover {
        transform: scale(1.05);
        cursor: pointer;
     }
`

const Image = styled.img`
    width: 100%;
    max-height:3.9cm;

    height: auto;
    
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

`

const ImageStandardise = styled.div`
    vertical-align: middle;
    position: relative;

    height:4cm;
    text-align: center;
    width:4cm;
    border:1px solid #f7f7f7;
    border-radius: 3px;
    padding:2px;
    background-color: #f7f7f7;

`

const Video = styled.video`
    height:4cm;
    width:4cm;
    border:1px solid #f7f7f7;
    border-radius: 3px;
    padding:2px;
    background-color: #333333;

`

const Nav = styled.div`
    width:100%;
    height: 70px;
    background-color: #ffffff;
    margin-bottom:10px;
`

const Heading = styled.h1`
    margin-top:10px;
    font-family: 'Trebuchet MS', sans-serif;
    margin-left:50px;
    float: left;
    padding: 0;
    margin-bottom: 50px;
    height: 0px;


`
const ImageName = styled.h1`
    float:left;
    font-family: 'Trebuchet MS', sans-serif;
    word-wrap: break-word;
    font-size: 20px;
    margin:0;
    


`
const ImageButton = styled.button`
    float:right;
    font-family: 'Trebuchet MS', sans-serif;
    word-wrap: break-word;
    font-size: 20px;
    margin:0;
    background-color: #fff;
    border:none;
    cursor: pointer;

`

const ButtonDark2 = styled.button`
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
    font-family: 'Trebuchet MS', sans-serif;
    
    height:30px;
    width:100px;
    margin-left:10px;
    margin-bottom: 10px;
    background-color: #f3f4ff;
    border:none;
    border-radius:3px;
    cursor: pointer;

`
const Button2 = styled.button`
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
    font-family: 'Trebuchet MS', sans-serif;
    height:30px;
    width:100px;
    margin-left:10px;
    margin-bottom: 10px;
    background-color: white;
    border:none;
    border-radius:3px;
    cursor: pointer;
`
const Button = styled.button`
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
    font-family: 'Trebuchet MS', sans-serif;

    height:30px;
    width:100px;
    margin-left:102px;
    margin-bottom: 10px;
    background-color: white;
    border:none;
    border-radius:3px;
    cursor: pointer;

`

const Input = styled.input`
    width: 30%;
    padding: 5px;
    margin-left:10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
`;
const ActionMenu = styled.div`
    position: absolute;
    top: calc(100% ); /* Adjust vertical position as needed */
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px;
    z-index: 100;
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    width: 130px;
    button {
        box-shadow:none;
        margin: 0;
        padding: 0;
        font-size: 14px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        outline: none;
        width: 100%;
        text-align: left;
        padding-left: 10px; /* Add left padding for content */
        color: #333; /* Text color */

        &:hover {
            background-color: #f3f3f3;
        }
    }
`;
// Styled component for the form container
const RenameFormContainer = styled.form`
    width:100%;
    display: inline-block; /* Ensures it wraps around the content */
`;

// Styled component for the input field
const RenameInput = styled.input`
    float:left;
    
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 200px; /* Adjust width as needed */
`;

// Styled component for the submit button
const RenameButton = styled.button`
    float:right;
    margin-right:10px;
    padding: 8px 16px;
    font-size: 16px;
    background-color: #ffffff; /* White background */
    border: 1px solid #ddd; /* Light border */
    border-radius: 4px;
    cursor: pointer;
`;
const ThumbnailGroup = () => {
    const [stateImages, setImages] = useState<any>(null);

    const [stateSelect, setSelect] = useState<any>(false);
    const [stateDelete, setDelete] = useState<any>(false);
    const [stateDeleteFiles, setDeleteFiles] = useState<any>({});
    const [stateView, setView] = useState<any>(null);
    const [currentFolderPath, setCurrentFolderPath] = useState('');

    const [stateUrls, setUrls] = useState<any>([]);
    const [filter, setFilter] = useState('');

    const [openMenuFor, setOpenMenuFor] = useState<any>('');
    const [renameKey, setRenameKey] = useState(null); // State to track which item/folder is being renamed
    const [renameVal, setRenameVal] = useState(null); // State to track which item/folder is being renamed


    const toggleMenu = (event, menuName) => {
    event.stopPropagation(); // Prevents the click event from bubbling up to the parent
    setOpenMenuFor(openMenuFor === menuName ? '' : menuName);
};
    const handleUpload = (event) => {
        event.preventDefault(); // Prevent default form submission

        const files = event.target.files;
        const allowedTypes = ['image/jpeg', 'image/png', 'video/mp4'];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (allowedTypes.includes(file.type)) {
                // Create a new FormData object
                let formData = new FormData();

                // Append the file to the FormData object
                formData.append('currentFolderPath', currentFolderPath);
                formData.append('file', file);
                

                // Send the file to the server using fetch
                fetch('https://tmximgsserver.dcapps.com.au/api/upload', { // Specify the full URL including port
                    method: 'POST',
                    body: formData
                })
                    .then(response => {
                        if (response.ok) {
                            console.log('File uploaded successfully');
                            event.target.value = null; // Reset file input value to trigger onChange again
                            (async () => {
                                const response = await fetch('./imagePaths.json');
                                const jsonData = await response.json();

                                setImages(jsonData);
                            })();
                        } else {
                            event.target.value = null; // Reset file input value to trigger onChange again
                            console.error('Failed to upload file:', response.statusText);
                            alert('Failed to upload file. Please try again.');

                        }
                    })
                    .catch(error => {
                        event.target.value = null; // Reset file input value to trigger onChange again
                        console.error('Error uploading file:', error);
                        alert('Error uploading file. Please try again.');

                    });
            }
        }
    };




    const handleSearch = (event) => {
        setFilter(event.target.value);
    };
    useEffect(() => {
        

        (async () => {
            const response = await fetch('./imagePaths.json');
            const jsonData = await response.json();

            setImages(jsonData);
        })();
    }, []);
    
    const click = (key) => {
        if (stateSelect) {

            let newURL = [...stateUrls]
            var index = newURL.indexOf(key);

            if (index === -1) {
                newURL.push(key);
            } else {
                newURL.splice(index, 1);
            }
            setUrls(newURL);
        }
        else {
            setView(key)
        }
    }
    
     const saveZip = (filename, urls) => {

        if (!urls) return;
        if (urls.length == 0) return;

        const zip = new JSZip();
        const folder = zip.folder("Files"); // folder name where all files will be placed in

        urls.forEach((url) => {
            const blobPromise = fetch(url.path).then((r) => {
                if (r.status === 200) return r.blob();
                return Promise.reject(new Error(r.statusText));
            });
            folder.file(url.name, blobPromise);
        });

        zip.generateAsync({ type: "blob" }).then((blob) => saveAs(blob, filename));

    };
    const download = () => {
        let urls = [];
        for (let i in stateUrls) {
            

            urls.push({ path: stateImages['images'][stateUrls[i]].path, name:stateImages['images'][stateUrls[i]].name });
        }

        saveZip("download.zip", urls);
    }
    


    const navigateToFolder = (key) => {
        console.log('nav folder key')
        console.log(key)
      //  setImages(stateImages['images'][key]['contents']);
        setCurrentFolderPath(prevState => `${prevState}/${key}`);
    };
    console.log('current folder')
    console.log(currentFolderPath)

    const navigateToParentFolder = () => {
    setCurrentFolderPath(prevState => {
        // Split the current path by '/' to an array
        const pathSegments = prevState.split('/');
        
        // Remove the last segment (current folder) from the array
        pathSegments.pop();
        
        // Join the array back into a string with '/'
        return pathSegments.join('/');
    });
    };

    const downloadFile = (file) => {
        let name = file.name;
        let path = file.path;
        fetch(path, {
            method: "GET",
            headers: {}
        })
            .then(response => {
                response.arrayBuffer().then(function (buffer) {
                    const url = window.URL.createObjectURL(new Blob([buffer]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", name); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                });
            })
            .catch(err => {
                console.log(err);
            });
    }

    const handleActionMenuClick = (event, action, data) => {
        event.stopPropagation(); // Stop event propagation to prevent closing the menu
        switch (action) {
            case 'delete':
                console.log('delete')
                deleteFilesByName(data)
                
                break;
            case 'rename':
                console.log('rename')
                setRenameKey(data); // Set the key of the item/folder to be renamed

                break;
            case 'download':
                console.log('download')
                downloadFile(data)
                break;
            default:
                console.log('Unknown action');
        }
    };



    const handleRenameSubmit = (event, newName) => {
        event.preventDefault();
        if (renameKey) {
            console.log('Renaming:', renameKey, newName);

            fetch('https://tmximgsserver.dcapps.com.au/api/rename', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ key: renameKey, newName: newName })
            })
            .then(response => {
                if (response.ok) {
                    (async () => {
                                const response = await fetch('./imagePaths.json');
                                const jsonData = await response.json();

                                setImages(jsonData);
                            })();
                } else {
                    throw new Error('Failed to rename.');
                }
            })
            .catch(error => {
                console.error('Error renaming:', error);
                alert('Failed to rename. Please try again.'); // Alert the user about the error
            });

            setRenameKey(null); // Clear rename state after renaming (optional)
            setRenameVal(null); // Clear input value state
        }
    };



    const handleDragStart = (e, item) => {
        e.dataTransfer.setData('text/plain', JSON.stringify(item));
    };
    const handleDrop = (e, folderItem) => {
        e.preventDefault();
        const data = e.dataTransfer.getData('text/plain');
        const draggedItem = JSON.parse(data);

        console.log('Dropped item:', draggedItem);
        console.log('Folder item:', folderItem);
        const DraggedKey = Object.keys(draggedItem)[0];
        const folderKey = Object.keys(folderItem)[0];
        if (typeof folderItem === 'string') {
            let segments = draggedItem[DraggedKey].folder.split('/');
            segments.pop();
            draggedItem[DraggedKey].folder = segments.join('/');

            
        }
        else {            
            if (DraggedKey == folderKey) {
                console.log('Cant Drop on itself')
                return
            }
            draggedItem[DraggedKey].folder += '/' + folderKey;

        }
        console.log('updated item')
        console.log(draggedItem)
        console.log(DraggedKey)
        console.log(draggedItem[DraggedKey].folder)
        fetch('https://tmximgsserver.dcapps.com.au/api/moveLocation', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ key: DraggedKey, folder: draggedItem[DraggedKey].folder })
            })
            .then(response => {
                if (response.ok) {
                    (async () => {
                                const response = await fetch('./imagePaths.json');
                                const jsonData = await response.json();

                                setImages(jsonData);
                            })();
                } else {
                    throw new Error('Failed to move.');
                }
            })
            .catch(error => {
                console.error('Error moving:', error);
                alert('Failed to move. Please try again.'); // Alert the user about the error
            });

    };


    const getRows = () => {
        
        let rows = [];
        if (stateImages == null) return;

        // Check if we're not at the top folder level
        if (currentFolderPath.length > 0) {
            // Add a special item to navigate back to the parent folder
            rows.push(
                <ImageContainer key={'parent-folder'} onClick={() => navigateToParentFolder()}
                    onDragOver={(e) => e.preventDefault()}  // Allow dropping
                    onDrop={(e) => handleDrop(e,  'parent' )} // Handle drop               
                >
                    {/*<ImageStandardise>*/}
                    {/*</ImageStandardise>*/}
                    <ImageName>../</ImageName>
                </ImageContainer>
            );
        }
        
        for (let key in stateImages['images']) {
            
            const item = stateImages['images'][key];
            if (item.type === 'folder' && item.folder == currentFolderPath) {
                
                // This is a folder
                const folderName = item['name'];
                if (!folderName.toLowerCase().includes(filter.toLowerCase())) {
                    continue;
                }
                rows.push(
                    <ImageContainer onMouseLeave={() => {  setOpenMenuFor('')}}  key={'folder' + key} onClick={() => {
                        if (!stateDelete) {

                            navigateToFolder(key)
                        }
                        else {
                                  setDeleteFiles(prevState => {
                                    const newState = { ...prevState };
                                    if (newState[key]) {
                                      delete newState[key]; // Delete the key if it exists
                                    } else {
                                      newState[key] = item; // Add the key if it doesn't exist
                                    }
                                    return newState;
                                  });
                                }
                            }}   style={{ backgroundColor: stateDeleteFiles.hasOwnProperty(key) ? '#f3f4ff' : '#ffffff' }}
                        onDragOver={(e) => e.preventDefault()}  // Allow dropping

                        onDrop={(e) => handleDrop(e, { [key]: item })} // Handle drop
                        draggable="true" // Make the image container draggable
                        onDragStart={(e) => handleDragStart(e, { [key]: item })} // Handle drag start
                              
                            >
                        {/*<ImageStandardise>*/}
                        {/*    <Image loading="lazy" src="./path/to/folder/icon.png" />*/}
                        {/*</ImageStandardise>*/}
                        {renameKey && renameKey === key ? (
                            <RenameFormContainer  onSubmit={(event) => handleRenameSubmit(event, renameVal)} onClick={(event) => event.stopPropagation()}>
                                <RenameInput 
                                    type="text"
                                    defaultValue={folderName}
                                    onChange={(event) => {
                                        event.stopPropagation(); // Stop propagation of clicks from parent elements
                                        setRenameVal(event.target.value);
                                    }}
                                />
                                <RenameButton type="submit">Rename</RenameButton>
                                <RenameButton onClick={() => setRenameKey(null)}>Cancel</RenameButton>
                            </RenameFormContainer >
                        ) : (
                            <div >
                                    <ImageName>{folderName}</ImageName>
                                    <ImageButton onClick={(event) => toggleMenu(event, key)}>&#8942;</ImageButton>
                                    <ActionMenu isOpen={openMenuFor === key}>
                                        <Button onClick={(event) => handleActionMenuClick(event, 'delete', { [key]:item })}>Delete</Button>
                                        <Button onClick={(event) => handleActionMenuClick(event, 'rename', key)}>Rename</Button>
                                    {/*    <Button onClick={(event) => handleActionMenuClick(event, 'download', item)}>Download</Button>*/}
                                    </ActionMenu>
                            </div>

                        )}

                        
                    </ImageContainer>
                );
            }
            else if(item.folder == currentFolderPath){
                // This is a regular image
                let name = item.name;
                const index = stateUrls.indexOf(key);
                
                if (!name.toLowerCase().includes(filter.toLowerCase())) {
                    continue;
                }
                if (name.split('.').pop() === 'mp4') {
                    if (index === -1) {
                        rows.push(
                            <ImageContainer key={'container' + key} onMouseLeave={() => {  setOpenMenuFor('')}}  onClick={() => {
                                if (!stateDelete) {
                                    click(key)
                                }
                                else {
                                  setDeleteFiles(prevState => {
                                    const newState = { ...prevState };
                                    if (newState[key]) {
                                      delete newState[key]; // Delete the key if it exists
                                    } else {
                                      newState[key] = item; // Add the key if it doesn't exist
                                    }
                                    return newState;
                                  });
                                }
                            }}  style={{ backgroundColor: stateDeleteFiles.hasOwnProperty(key) ? '#f3f4ff' : '#ffffff' }}
                            draggable="true" // Make the image container draggable
                            onDragStart={(e) => handleDragStart(e, { [key]: item })} // Handle drag start
                            >
                                {/*<Video preload="none" loading="lazy" key={key} >*/}
                                {/*    <source key={'source' + key} src={item.path} type="video/mp4" />*/}
                                {/*</Video>*/}
                                
                                
                                {renameKey && renameKey === key ? (
                                <RenameFormContainer  onSubmit={(event) => handleRenameSubmit(event, renameVal)} onClick={(event) => event.stopPropagation()}>
                                    <RenameInput 
                                        type="text"
                                        defaultValue={name}
                                        onChange={(event) => {
                                            event.stopPropagation(); // Stop propagation of clicks from parent elements
                                            setRenameVal(event.target.value);
                                        }}
                                    />
                                    <RenameButton type="submit">Rename</RenameButton>
                                    <RenameButton onClick={() => setRenameKey(null)}>Cancel</RenameButton>
                                </RenameFormContainer >
                            ) : (
                                <div >
                                        <ImageName key={'ImageName' + key}>{name}</ImageName>
                                        <ImageButton onClick={(event) => toggleMenu(event, key)}>&#8942;</ImageButton>
                                        <ActionMenu isOpen={openMenuFor === key}>
                                            <Button onClick={(event) => handleActionMenuClick(event, 'delete', { [key]:item })}>Delete</Button>
                                            <Button onClick={(event) => handleActionMenuClick(event, 'rename', key)}>Rename</Button>
                                            <Button onClick={(event) => handleActionMenuClick(event, 'download', item)}>Download</Button>
                                        </ActionMenu>
                                </div>

                            )}
                            </ImageContainer>

                        );
                    } else {
                        rows.push(
                            <ImageContainerSelected key={'container' + key}  onMouseLeave={() => { setOpenMenuFor('')}}   onClick={() => {
                                if (!stateDelete) {
                                    click(key)
                                }
                                else {
                                  setDeleteFiles(prevState => {
                                    const newState = { ...prevState };
                                    if (newState[key]) {
                                      delete newState[key]; // Delete the key if it exists
                                    } else {
                                      newState[key] = item; // Add the key if it doesn't exist
                                    }
                                    return newState;
                                  });
                                }
                            }}
                                style={{ backgroundColor: stateDeleteFiles.hasOwnProperty(key) ? '#f3f4ff' : '#ffffff' }}
                            draggable="true" // Make the image container draggable
                            onDragStart={(e) => handleDragStart(e, { [key]: item })} // Handle drag start
                            >                            
                                {/*<Video preload="none" loading="lazy" key={key} >*/}
                                {/*    <source key={'source' + key} src={item.path} type="video/mp4" />*/}
                                {/*</Video>*/}
                                {renameKey && renameKey === key ? (
                                <RenameFormContainer  onSubmit={(event) => handleRenameSubmit(event, renameVal)} onClick={(event) => event.stopPropagation()}>
                                    <RenameInput 
                                        type="text"
                                        defaultValue={name}
                                        onChange={(event) => {
                                            event.stopPropagation(); // Stop propagation of clicks from parent elements
                                            setRenameVal(event.target.value);
                                        }}
                                    />
                                    <RenameButton type="submit">Rename</RenameButton>
                                    <RenameButton onClick={() => setRenameKey(null)}>Cancel</RenameButton>
                                </RenameFormContainer >
                            ) : (
                                <div >
                                        <ImageName key={'ImageName' + key}>{name}</ImageName>
                                        <ImageButton onClick={(event) => toggleMenu(event, key)}>&#8942;</ImageButton>
                                        <ActionMenu isOpen={openMenuFor === key}>
                                            <Button onClick={(event) => handleActionMenuClick(event, 'delete', { [key]:item })}>Delete</Button>
                                            <Button onClick={(event) => handleActionMenuClick(event, 'rename', key)}>Rename</Button>
                                            <Button onClick={(event) => handleActionMenuClick(event, 'download', item)}>Download</Button>
                                        </ActionMenu>
                                </div>

                            )}
                            </ImageContainerSelected>
                        );
                    }
                }
                else {
                    if (index === -1) {
                        rows.push(
                            <ImageContainer key={'container' + key} onMouseLeave={() => {  setOpenMenuFor('')}}   onClick={() => {
                                if (!stateDelete) {
                                    click(key)
                                }
                                else {
                                  setDeleteFiles(prevState => {
                                    const newState = { ...prevState };
                                    if (newState[key]) {
                                      delete newState[key]; // Delete the key if it exists
                                    } else {
                                      newState[key] = item; // Add the key if it doesn't exist
                                    }
                                    return newState;
                                  });
                                }
                            }}
                                style={{ backgroundColor: stateDeleteFiles.hasOwnProperty(key) ? '#f3f4ff' : '#ffffff' }}
                            draggable="true" // Make the image container draggable
                            onDragStart={(e) => handleDragStart(e, { [key]: item })} // Handle drag start
                            >   
                                {/*<ImageStandardise>*/}
                                {/*    <Image loading="lazy" key={key} src={item.path} />*/}
                                {/*</ImageStandardise>*/}
                                {renameKey && renameKey === key ? (
                                <RenameFormContainer  onSubmit={(event) => handleRenameSubmit(event, renameVal)} onClick={(event) => event.stopPropagation()}>
                                    <RenameInput 
                                        type="text"
                                        defaultValue={name}
                                        onChange={(event) => {
                                            event.stopPropagation(); // Stop propagation of clicks from parent elements
                                            setRenameVal(event.target.value);
                                        }}
                                    />
                                    <RenameButton type="submit">Rename</RenameButton>
                                    <RenameButton onClick={() => setRenameKey(null)}>Cancel</RenameButton>
                                </RenameFormContainer >
                            ) : (
                                <div >
                                        <ImageName key={'ImageName' + key}>{name}</ImageName>
                                        <ImageButton onClick={(event) => toggleMenu(event, key)}>&#8942;</ImageButton>
                                        <ActionMenu isOpen={openMenuFor === key}>
                                            <Button onClick={(event) => handleActionMenuClick(event, 'delete', { [key]:item })}>Delete</Button>
                                            <Button onClick={(event) => handleActionMenuClick(event, 'rename', key)}>Rename</Button>
                                            <Button onClick={(event) => handleActionMenuClick(event, 'download', item)}>Download</Button>
                                        </ActionMenu>
                                </div>

                            )}
                            </ImageContainer>
                        );
                    } else {
                        rows.push(
                            <ImageContainerSelected key={'container' + key} onMouseLeave={() => {  setOpenMenuFor('')}}  onClick={() => {
                                if (!stateDelete) {
                                    click(key)
                                }
                                else {
                                  setDeleteFiles(prevState => {
                                    const newState = { ...prevState };
                                    if (newState[key]) {
                                      delete newState[key]; // Delete the key if it exists
                                    } else {
                                      newState[key] = item; // Add the key if it doesn't exist
                                    }
                                    return newState;
                                  });
                                }
                            }}
                                style={{ backgroundColor: stateDeleteFiles.hasOwnProperty(key) ? '#f3f4ff' : '#ffffff' }}
                            draggable="true" // Make the image container draggable
                            onDragStart={(e) => handleDragStart(e, { [key]: item })} // Handle drag start
                            >  
                                {/*<ImageStandardise>*/}
                                {/*    <Image loading="lazy" key={key} src={item.path} />*/}
                                {/*</ImageStandardise>*/}
                                {renameKey && renameKey === key ? (
                                <RenameFormContainer  onSubmit={(event) => handleRenameSubmit(event, renameVal)} onClick={(event) => event.stopPropagation()}>
                                    <RenameInput 
                                        type="text"
                                        defaultValue={name}
                                        onChange={(event) => {
                                            event.stopPropagation(); // Stop propagation of clicks from parent elements
                                            setRenameVal(event.target.value);
                                        }}
                                    />
                                    <RenameButton type="submit">Rename</RenameButton>
                                    <RenameButton onClick={() => setRenameKey(null)}>Cancel</RenameButton>
                                </RenameFormContainer >
                            ) : (
                                <div >
                                        <ImageName key={'ImageName' + key}>{name}</ImageName>
                                        <ImageButton onClick={(event) => toggleMenu(event, key)}>&#8942;</ImageButton>
                                        <ActionMenu isOpen={openMenuFor === key}>
                                            <Button onClick={(event) => handleActionMenuClick(event, 'delete', { [key]:item })}>Delete</Button>
                                            <Button onClick={(event) => handleActionMenuClick(event, 'rename', key)}>Rename</Button>
                                            <Button onClick={(event) => handleActionMenuClick(event, 'download', item)}>Download</Button>
                                        </ActionMenu>
                                </div>

                            )}
                            </ImageContainerSelected>
                        );
                    }
                }
            }
        }
        if (stateView != null) {
            rows.push(
                <ThumbnailView key="view" view={stateImages['images'][stateView]} close={() => { setView(null) }}>
                </ThumbnailView>
            );
        }
        return rows;
    };
    const fileInputRef = useRef(null);

    const handleUploadClick = (event) => {
        event.preventDefault(); // Prevent default form submission

        fileInputRef.current.click();
    };
    const deleteFilesByName = (files) => {
      // Extract the file names and keys from stateDeleteFiles
      const filesToDelete = Object.values(files).map((file: any) => file.name);
      const keysToDelete = Object.keys(files);
      // Prepare the confirmation message
      const message = `Are you sure you want to delete the following files?\n\n${filesToDelete.join('\n')}`;

      // Show the confirmation dialog
      const confirmDelete = window.confirm(message);

      // If user confirms, proceed with deletion
      if (confirmDelete) {
        // Perform deletion logic here
        console.log('Files deleted:', filesToDelete);
        console.log('Keys to delete:', keysToDelete);
    
        // Send the keys to the server for deletion
        fetch('https://tmximgsserver.dcapps.com.au/api/delete', { // Specify the delete endpoint URL
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ keys: keysToDelete }) // Send the keys to be deleted
        })
          .then(response => {
            if (response.ok) {
              console.log('Files deleted successfully on server');
                (async () => {
                    const response = await fetch('./imagePaths.json');
                    const jsonData = await response.json();

                    setImages(jsonData);
                })();
            } else {
              console.error('Failed to delete files on server:', response.statusText);
              alert('Failed to delete files on server. Please try again.');
            }
          })
          .catch(error => {
            console.error('Error deleting files on server:', error);
            alert('Error deleting files on server. Please try again.');
          });

        // Clear the state
        setDeleteFiles({});
      } else {
        console.log('Deletion canceled');
        // Optionally, you can clear the state or keep it as is
        setDeleteFiles({});
      }
    };

    const deleteFiles = () => {
      // Extract the file names and keys from stateDeleteFiles
      const filesToDelete = Object.values(stateDeleteFiles).map((file: any) => file.name);
      const keysToDelete = Object.keys(stateDeleteFiles);
      // Prepare the confirmation message
      const message = `Are you sure you want to delete the following files?\n\n${filesToDelete.join('\n')}`;

      // Show the confirmation dialog
      const confirmDelete = window.confirm(message);

      // If user confirms, proceed with deletion
      if (confirmDelete) {
        // Perform deletion logic here
        console.log('Files deleted:', filesToDelete);
        console.log('Keys to delete:', keysToDelete);
    
        // Send the keys to the server for deletion
        fetch('https://tmximgsserver.dcapps.com.au/api/delete', { // Specify the delete endpoint URL
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ keys: keysToDelete }) // Send the keys to be deleted
        })
          .then(response => {
            if (response.ok) {
              console.log('Files deleted successfully on server');
                (async () => {
                    const response = await fetch('./imagePaths.json');
                    const jsonData = await response.json();

                    setImages(jsonData);
                })();
            } else {
              console.error('Failed to delete files on server:', response.statusText);
              alert('Failed to delete files on server. Please try again.');
            }
          })
          .catch(error => {
            console.error('Error deleting files on server:', error);
            alert('Error deleting files on server. Please try again.');
          });

        // Clear the state
        setDeleteFiles({});
      } else {
        console.log('Deletion canceled');
        // Optionally, you can clear the state or keep it as is
        setDeleteFiles({});
      }
    };

    const addFolder = async () => {
        try {
            const response = await fetch('https://tmximgsserver.dcapps.com.au/api/addFolder', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ folderName: 'New Folder', currentFolderPath }), // Include currentFolderPath
            });

            if (!response.ok) {
                throw new Error('Failed to add folder');
            }

            // Update images state after adding folder
            const imageDataResponse = await fetch('./imagePaths.json');
            const jsonData = await imageDataResponse.json();
            setImages(jsonData);
        } catch (error) {
            console.error('Error adding folder:', error);
            alert('Error adding folder: '+ error);
            // Handle error, show alert or feedback to user
        }
    };

    return (
        
        <div>
            <Nav>
                <Heading>Download Files:</Heading>
            </Nav>
            <Button onClick={() => { download() }}>Download</Button>
            {stateSelect && <ButtonDark2 onClick={() => { setSelect(false); setUrls([]) }}>Multi Select</ButtonDark2>}
            {!stateSelect && <Button2 onClick={() => { setSelect(true) }}>Multi Select</Button2>}
            <Input
                type="text"
                placeholder="Search..."
                onChange={handleSearch} // Update here
            />
            <Button2 onClick={handleUploadClick}>Upload</Button2>
            {stateDelete && <ButtonDark2 onClick={() => { setDelete(false); setDeleteFiles({})}}>Delete</ButtonDark2>}
            {stateDelete && <Button2 onClick={() => { setDelete(false); deleteFiles()}}>Confirm</Button2>}

            {!stateDelete && <Button2 onClick={() => { setDelete(true) }}>Delete</Button2>}
            <Button2 onClick={() => { addFolder()}}>Add Folder</Button2>
            <Input id="file-upload" multiple type="file" accept="image/*,video/*" onChange={handleUpload} style={{ display: 'none' }} ref={fileInputRef} />

            <ParentContainer>
                <Container>
                    {getRows()}
                </Container>
            </ParentContainer>
        </div>


        );
    
}

export default ThumbnailGroup;